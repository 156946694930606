import { FC } from 'react';

import IIcon from 'src/interfaces/icon.interface';

const mail: FC<IIcon> = () => {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 -4 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>mail</title>
      <desc>Created with Sketch Beta.</desc>
      <defs></defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icon-Set"
          transform="translate(-412.000000, -259.000000)"
          fill="#ffffff"
        >
          <path
            d="M442,279 C442,279.203 441.961,279.395 441.905,279.578 L433,270 L442,263 L442,279 L442,279 Z M415.556,280.946 L424.58,271.33 L428,273.915 L431.272,271.314 L440.444,280.946 C440.301,280.979 415.699,280.979 415.556,280.946 L415.556,280.946 Z M414,279 L414,263 L423,270 L414.095,279.578 C414.039,279.395 414,279.203 414,279 L414,279 Z M441,261 L428,271 L415,261 L441,261 L441,261 Z M440,259 L416,259 C413.791,259 412,260.791 412,263 L412,279 C412,281.209 413.791,283 416,283 L440,283 C442.209,283 444,281.209 444,279 L444,263 C444,260.791 442.209,259 440,259 L440,259 Z"
            id="mail"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default mail;
