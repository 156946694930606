import { FC } from 'react';

import IIcon from 'src/interfaces/icon.interface';

const logo: FC<IIcon> = ({ className, height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className ? className : ''}
      viewBox="0 0 178 39"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 25.9679V23.3711L8.65719 12.2626V19.2355L4.23241 24.6695L8.65719 30.1036V37.0765L0 25.9679ZM9.85958 25.9679V23.3711L18.613 12.2626V19.2355L14.1401 24.6695L18.613 30.1036V37.0765L9.85958 25.9679Z"
        fill="#FD07B2"
      />
      <path
        d="M29.8038 0H37.4029L45.1944 38.9519H38.6053L37.0662 29.9593H30.2848L28.6976 38.9519H22.0123L29.8038 0ZM36.2967 25.439L33.6515 9.08878L31.0062 25.439H36.2967Z"
        fill="#FD07B2"
      />
      <path
        d="M49.6894 0H57.048L62.579 26.9778L68.3024 0H75.4686L76.1901 38.9519H70.8996L70.3224 11.8779L64.7914 38.9519H60.5109L54.8838 11.7818L54.3547 38.9519H49.0161L49.6894 0Z"
        fill="#FD07B2"
      />
      <path
        d="M81.9431 0H98.2956V5.00123H89.0132V16.2059H96.3237V21.1591H89.0132V34.0949H98.3918V38.9519H81.9431V0Z"
        fill="#FD07B2"
      />
      <path
        d="M99.8219 33.8545C101.457 33.8866 102.627 33.6301 103.333 33.0851C104.07 32.508 104.631 31.2256 105.016 29.238C105.401 27.2503 105.77 23.836 106.122 18.9951L107.373 0H126.323V38.9519H119.252V5.33785H113.625L112.808 19.8607C112.487 25.0543 112.054 28.9174 111.509 31.4501C110.964 33.9507 109.938 35.8422 108.431 37.1245C106.956 38.3748 104.663 39 101.553 39C100.816 39 100.239 38.984 99.8219 38.9519V33.8545Z"
        fill="#FD07B2"
      />
      <path
        d="M132.436 0H138.256V24.5734L148.933 0H153.694V38.9519H147.779V13.9457L137.102 38.9519H132.436V0Z"
        fill="#FD07B2"
      />
      <path
        d="M159.195 30.1036L163.86 24.6695L159.195 19.2355V12.2626L168.14 23.3711V25.9679L159.195 37.0765V30.1036ZM169.102 30.1036L173.768 24.6695L169.102 19.2355V12.2626L178 23.3711V25.9679L169.102 37.0765V30.1036Z"
        fill="#FD07B2"
      />
    </svg>
  );
};

export default logo;
