import { FC } from 'react';

import IIcon from 'src/interfaces/icon.interface';

const guarantee: FC<IIcon> = ({ width, height }) => {
  return (
    <svg
      fill="#000000"
      height={height}
      width={width}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-50 -50 400 400"
    >
      <g>
        <path
          d="M263.89,8.743c-3.039,0-5.503,2.464-5.503,5.503v257.607c0,0.854,0.199,1.696,0.581,2.461l14.829,29.654
		c0.932,1.864,2.837,3.042,4.922,3.042c2.084,0,3.989-1.178,4.922-3.042l14.828-29.654c0.382-0.765,0.581-1.606,0.581-2.461V14.246
		c0-3.039-2.464-5.503-5.503-5.503H263.89z M288.044,270.552l-9.325,18.649l-9.326-18.649V19.749h18.651V270.552z"
        />
        <path
          d="M304.746,48.037v87.789c0,3.039,2.464,5.503,5.503,5.503c3.039,0,5.503-2.464,5.503-5.503V48.037
		c0-3.039-2.464-5.503-5.503-5.503C307.21,42.534,304.746,44.998,304.746,48.037z"
        />
        <path
          d="M186.526,164.64H47.204c-3.039,0-5.503,2.465-5.503,5.504c0,3.039,2.464,5.502,5.503,5.502h139.322
		c3.039,0,5.503-2.463,5.503-5.502C192.029,167.105,189.565,164.64,186.526,164.64z"
        />
        <path
          d="M186.526,246.068H47.204c-3.039,0-5.503,2.464-5.503,5.503c0,3.039,2.464,5.503,5.503,5.503h139.322
		c3.039,0,5.503-2.464,5.503-5.503C192.029,248.532,189.565,246.068,186.526,246.068z"
        />
        <path
          d="M186.526,129.295H47.204c-3.039,0-5.503,2.464-5.503,5.503c0,3.039,2.464,5.503,5.503,5.503h139.322
		c3.039,0,5.503-2.464,5.503-5.503C192.029,131.759,189.565,129.295,186.526,129.295z"
        />
        <path
          d="M47.204,104.959h82.709c3.039,0,5.503-2.465,5.503-5.504c0-3.039-2.464-5.502-5.503-5.502H47.204
		c-3.039,0-5.503,2.463-5.503,5.502C41.701,102.494,44.165,104.959,47.204,104.959z"
        />
        <path
          d="M47.204,69.615h82.709c3.039,0,5.503-2.463,5.503-5.502c0-3.039-2.464-5.504-5.503-5.504H47.204
		c-3.039,0-5.503,2.465-5.503,5.504C41.701,67.152,44.165,69.615,47.204,69.615z"
        />
        <path
          d="M5.488,306.568h222.754c3.031,0,5.488-2.457,5.488-5.488V87.843l0,0l0,0c0-0.008-0.001-0.015-0.001-0.022
		c-0.001-0.177-0.01-0.353-0.027-0.525c-0.013-0.134-0.036-0.265-0.059-0.396c-0.008-0.045-0.012-0.091-0.02-0.136
		c-0.031-0.154-0.071-0.308-0.115-0.459c-0.006-0.02-0.01-0.04-0.016-0.061c-0.047-0.15-0.102-0.299-0.16-0.446
		c-0.008-0.019-0.014-0.039-0.021-0.058c-0.057-0.138-0.122-0.271-0.191-0.402c-0.014-0.027-0.025-0.057-0.04-0.084
		c-0.064-0.119-0.136-0.234-0.208-0.349c-0.024-0.037-0.045-0.077-0.069-0.113c-0.069-0.103-0.146-0.202-0.223-0.301
		c-0.033-0.043-0.064-0.089-0.098-0.132c-0.095-0.115-0.197-0.225-0.303-0.333c-0.02-0.021-0.036-0.043-0.057-0.063l-73.174-73.173
		c-0.127-0.127-0.26-0.246-0.397-0.359c-0.036-0.029-0.075-0.055-0.111-0.083c-0.105-0.082-0.211-0.164-0.32-0.237
		c-0.032-0.021-0.067-0.04-0.1-0.061c-0.118-0.076-0.238-0.15-0.361-0.217c-0.022-0.012-0.047-0.021-0.069-0.033
		c-0.137-0.071-0.275-0.139-0.417-0.197c-0.016-0.007-0.032-0.012-0.047-0.018c-0.15-0.061-0.303-0.116-0.457-0.164
		c-0.018-0.005-0.035-0.008-0.053-0.013c-0.153-0.046-0.309-0.086-0.467-0.118c-0.043-0.008-0.089-0.012-0.132-0.02
		c-0.133-0.023-0.266-0.047-0.4-0.06c-0.181-0.019-0.364-0.028-0.548-0.028l0,0H5.488C2.457,9.183,0,11.64,0,14.671V301.08
		C0,304.111,2.457,306.568,5.488,306.568z M214.995,82.357l-54.434,0.006l-0.003-54.441L214.995,82.357z M10.976,20.16h138.605
		l0.004,67.692c0,1.456,0.578,2.852,1.607,3.881c1.029,1.028,2.426,1.606,3.881,1.606l67.682-0.008v202.261H10.976V20.16z"
        />
      </g>
    </svg>
  );
};

export default guarantee;
