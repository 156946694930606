import { FC } from 'react';

import IIcon from 'src/interfaces/icon.interface';

const photos: FC<IIcon> = ({ className, height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className ? className : ''}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          d="M2405 5105 c-263 -50 -482 -215 -604 -455 -26 -53 -50 -102 -53 -110
-3 -11 -17 -9 -69 13 -257 108 -558 80 -789 -72 -345 -227 -481 -663 -323
-1040 22 -52 24 -66 13 -69 -8 -3 -57 -27 -110 -53 -425 -215 -592 -729 -374
-1149 84 -162 210 -286 374 -369 53 -26 102 -50 110 -53 11 -3 9 -17 -13 -69
-39 -93 -57 -172 -64 -279 -25 -419 266 -795 682 -881 152 -32 317 -19 460 36
39 14 77 28 85 31 11 3 27 -22 59 -91 118 -256 362 -441 641 -485 371 -58 743
142 901 485 32 69 48 94 59 91 8 -3 47 -17 85 -31 143 -55 308 -68 460 -36
527 109 824 667 618 1160 -22 52 -24 66 -13 69 8 3 57 27 110 53 164 83 290
207 374 369 218 420 51 934 -374 1149 -53 26 -102 50 -110 53 -11 3 -9 17 13
69 108 257 80 558 -72 789 -227 345 -663 481 -1040 323 -52 -22 -66 -24 -69
-13 -3 8 -27 57 -53 110 -172 339 -545 525 -914 455z m283 -171 c150 -31 264
-95 369 -207 67 -72 121 -161 148 -246 l17 -54 -331 -331 -331 -331 -331 331
-331 331 17 54 c56 175 209 340 381 412 129 55 260 68 392 41z m-1163 -509
c33 -9 88 -29 123 -45 l62 -29 -2 -468 -3 -468 -467 -3 -468 -2 -25 49 c-127
252 -80 569 114 775 170 180 427 253 666 191z m2373 9 c438 -90 671 -555 482
-962 l-29 -62 -468 2 -468 3 -3 467 -2 467 41 22 c63 32 117 49 194 64 96 18
162 18 253 -1z m-1575 -906 l-118 -118 -162 0 -163 0 0 397 0 398 280 -280
280 -280 -117 -117z m917 277 l0 -395 -162 0 -163 0 -117 117 -118 118 277
277 c153 153 279 278 280 278 2 0 3 -178 3 -395z m-620 -340 l54 -55 -114 0
-114 0 54 55 c30 30 57 55 60 55 3 0 30 -25 60 -55z m-910 -388 l0 -162 -118
-118 -117 -117 -280 280 -280 280 398 0 397 0 0 -163z m320 160 c0 -1 -34 -36
-75 -77 l-75 -74 0 77 0 77 75 0 c41 0 75 -1 75 -3z m1012 -194 l198 -198 0
-285 0 -285 -197 -197 -198 -198 -285 0 -285 0 -197 197 -198 198 0 285 0 285
197 197 198 198 285 0 285 0 197 -197z m198 120 l0 -77 -75 74 c-41 41 -75 76
-75 77 0 2 34 3 75 3 l75 0 0 -77z m685 -203 l-280 -280 -117 117 -118 118 0
162 0 163 397 0 398 0 -280 -280z m-2900 -70 l330 -330 -331 -331 -331 -331
-54 17 c-175 56 -340 209 -412 381 -31 72 -57 195 -57 264 0 69 26 192 57 264
70 167 227 316 395 376 29 10 58 19 63 19 6 1 159 -148 340 -329z m3469 310
c170 -58 328 -207 399 -376 31 -72 57 -195 57 -264 0 -69 -26 -192 -57 -264
-72 -172 -237 -325 -412 -381 l-54 -17 -331 331 -331 331 330 330 c181 181
333 330 336 330 3 0 32 -9 63 -20z m-2784 -642 l0 -113 -57 57 -58 58 55 55
c30 30 56 55 57 55 2 0 3 -51 3 -112z m1757 -55 l-57 -58 0 115 0 115 57 -58
58 -57 -58 -57z m-1757 -460 l0 -163 -397 0 -398 0 280 280 280 280 117 -117
118 -118 0 -162z m2215 117 l280 -280 -398 0 -397 0 0 162 0 163 117 117 c65
65 118 118 118 118 0 0 126 -126 280 -280z m-1965 -205 l74 -75 -77 0 -77 0 0
75 c0 41 1 75 3 75 1 0 36 -34 77 -75z m1280 0 l0 -75 -77 0 -77 0 74 75 c41
41 76 75 77 75 2 0 3 -34 3 -75z m-1532 -717 l2 -468 -49 -25 c-261 -132 -592
-76 -795 135 -102 106 -159 222 -187 380 -20 114 9 287 66 401 l25 49 468 -2
467 -3 3 -467z m615 354 l117 -117 -280 -280 -280 -280 0 398 0 397 163 0 162
0 118 -118z m294 61 l-57 -58 -57 58 -58 57 115 0 115 0 -58 -57z m623 -340
l0 -398 -280 280 -280 280 117 117 118 118 162 0 163 0 0 -397z m1140 335
c125 -269 69 -581 -140 -782 -106 -102 -222 -159 -380 -187 -114 -20 -287 9
-401 66 l-49 25 0 464 c0 254 3 466 7 469 3 4 215 7 470 7 l464 0 29 -62z
m-1486 -627 l328 -329 -17 -53 c-56 -175 -209 -340 -381 -412 -72 -31 -195
-57 -264 -57 -69 0 -192 26 -264 57 -172 72 -325 237 -381 412 l-17 53 328
329 c181 181 331 329 334 329 3 0 153 -148 334 -329z"
        />
      </g>
    </svg>
  );
};

export default photos;
