import { FC } from 'react';

import IIcon from 'src/interfaces/icon.interface';

const clock: FC<IIcon> = ({ className, height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className ? className : ''}
      fill="#000000"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-50 -50 592.308 592.308"
    >
      <g>
        <g>
          <path
            d="M246.154,0.002C110.423,0.002,0,110.426,0,246.151c0,135.731,110.423,246.154,246.154,246.154
			s246.154-110.423,246.154-246.154C492.308,110.426,381.885,0.002,246.154,0.002z M246.154,472.613
			c-124.875,0-226.462-101.587-226.462-226.462c0-124.87,101.587-226.457,226.462-226.457s226.462,101.586,226.462,226.457
			C472.615,371.026,371.029,472.613,246.154,472.613z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon points="236.308,87.488 236.308,236.305 118.981,236.305 118.981,255.998 256,255.998 256,87.488 		" />
        </g>
      </g>
      <g>
        <g>
          <rect x="236.308" y="40.586" width="19.692" height="19.692" />
        </g>
      </g>
      <g>
        <g>
          <rect
            x="97.912"
            y="97.917"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -44.6386 107.7594)"
            width="19.692"
            height="19.692"
          />
        </g>
      </g>
      <g>
        <g>
          <rect x="40.586" y="236.308" width="19.692" height="19.692" />
        </g>
      </g>
      <g>
        <g>
          <rect
            x="97.935"
            y="374.755"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -240.386 188.8602)"
            width="19.692"
            height="19.692"
          />
        </g>
      </g>
      <g>
        <g>
          <rect x="236.308" y="432.03" width="19.692" height="19.692" />
        </g>
      </g>
      <g>
        <g>
          <rect
            x="374.761"
            y="374.745"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -159.298 384.6023)"
            width="19.692"
            height="19.692"
          />
        </g>
      </g>
      <g>
        <g>
          <rect x="432.03" y="236.308" width="19.692" height="19.692" />
        </g>
      </g>
      <g>
        <g>
          <rect
            x="374.737"
            y="97.887"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 36.4634 303.4958)"
            width="19.692"
            height="19.692"
          />
        </g>
      </g>
    </svg>
  );
};

export default clock;
